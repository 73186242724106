const _staffTitle = $('.js-staffTitle');
const _staffYearBtn = $('.js-staffSearchYearBtn');
const _staffCheckLabel = $('.p-staffSearch__contentLabel');
const _URL = '/wp-json/wp/v2/staff';
let _cfArea = [];
let _cfYear = [];
let _cfVisit = [];
let _cfLicense = [];
let _list = $('#js-staffList')
let _post = [];

if(_staffTitle.length){
	_staffTitle.click(function(){
		let _e = $(this);
		_e.parent('.js-staff').toggleClass('is-open');
		_e.next('.js-staffContent').slideToggle();
	})
	_staffYearBtn.click(function(){
		let _e = $(this);
		let _year = _e.data('year');
		if(!_e.hasClass('is-selected')){
			_staffYearBtn.each(function(){
				let _btn = $(this);
				let _btnYear = _btn.data('year');
				let _btnVal = _btn.data('val');
				if (_btnYear >= _year) {
					_btn.addClass('is-selected');
					_cfYear.push(_btnVal);
				} else {
					_btn.removeClass('is-selected');
				}
			});
		}else{

			_staffYearBtn.removeClass('is-selected');
			_cfYear = [];
		}
		_staffSearch()
	})
	_staffCheckLabel.change(function(){
		let _e = $(this);
		let checkedInputs = _staffCheckLabel.find('input:checked');
		_cfArea = [];
		_cfVisit = [];
		_cfLicense = [];
		checkedInputs.each(function() {
			let _input = $(this);
			let _name = _input.attr('name');
			let _value = _input.val();
			if(_name === 'area'){
				_cfArea.push(_value);
			}else if(_name === 'visit'){
				_cfVisit.push(_value);
			}else if(_name === 'license'){
				_cfLicense.push(_value);
			}
		});
		_staffSearch()
	})

	function _staffSearch(){
		let _param = '';
		if(_cfArea.length){
			_param += '&tax-area=' + _cfArea.join(',');
		}
		if(_cfYear.length){
			_param += '&tax-year=' + _cfYear.join(',');
		}
		if(_cfVisit.length){
			_param += '&tax-visit=' + _cfVisit.join(',');
		}
		if(_cfLicense.length){
			_param += '&tax-license=' + _cfLicense.join(',');
		}
		_list.empty();
		$.ajax({
			url: _URL+'?'+_param+'&status=publish&per_page=100&page=1',
			type: 'GET',
			dataType: 'json',
			success: function(data){
				_post = [];
				_post = data;
				for (let i = 0; i < _post.length; i++){
					let _title = _post[i].title.rendered;
					let _img = _post[i].cf_img_list;
					let _link = _post[i].link;
					let _reserveUrl = _post[i].acf.cf_url;
					let _position = _post[i].acf.cf_position;
					let _nameEn = _post[i].acf['cf_name-en'];
					let _area = _post[i].taxonomies['tax-area'];
					let _areaName = [];
					$.each(_area,function(index,value){
						_areaName.push('<span class="l-tag p-staffItem__tag">'+value.name+'</span>');
					})
					if(_areaName.length == 0){
						_areaName.push('');
					}
					let _year = _post[i].taxonomies['tax-year'];
					let _yearName = [];
					$.each(_year,function(index,value){
						_yearName.push('<span class="l-tag p-staffItem__tag">'+value.name+'</span>');
					})
					if(_yearName.length == 0){
						_yearName.push('');
					}
					let _license = _post[i].taxonomies['tax-license'];
					let _licenseName = [];
					$.each(_license,function(index,value){
						_licenseName.push('<span class="l-tag p-staffItem__tag">'+value.name+'</span>');
					})
					if(_licenseName.length == 0){
						_licenseName.push('');
					}
					let _visit = _post[i].taxonomies['tax-visit'];
					let _visitName = [];
					$.each(_visit,function(index,value){
						_visitName.push('<span class="l-tag p-staffItem__tag">'+value.name+'</span>');
					})
					if(_visitName.length == 0){
						_visitName.push('');
					}
					let _item = `<li class="p-staff__item">
							<div class="p-staffItem__figure">
								<img class="p-staffItem__img" src="${_img}" alt="${_title}">
							</div>
							<p class="p-staffItem__name">
								${_title}<br>
								<span>${_nameEn}</span>
							</p>
							<p class="p-staffItem__position">
								${_position}
							</p>
							<div class="l-tagWrapper p-staffItem__tagWrapper">
								${_yearName.join('')}
								${_licenseName.join('')}
								${_areaName.join('')}
								${_visitName.join('')}
							</div>
							<div class="c-btn__wrapper p-staffItem__btnWrapper">
								<a class="c-btn -quaternary p-staffItem__btn" href="${_link}">
									<span class="c-btn__text">プロフィールを見る</span><span class="c-btn__icon -right -primary"></span>
								</a>
								<a class="c-btn -primary p-staffItem__btn" href="${_reserveUrl}"><span class="c-btn__text">指名予約をする</span><span class="c-btn__icon -right -primary"></span>
								</a>
							</div>
						</li>`
					_list.append(_item);
				}
			},
			error: function(){
				console.log('error');
			}
		});
	}
}

