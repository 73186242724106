'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
import { height } from './view/_height';
import { scroll } from './view/_scroll';
import { header } from './view/_header';
import { evaluation } from './view/_evaluation';
import { faq } from './view/_faq';
import { tab } from './view/_tab';
import { slider } from './view/_slider';
import { staff } from './view/_staff';
import { btnMore } from './view/_btnMore';
// import { anchorMore } from './view/_anchorMore';
// import { bg } from './view/_bg';
// import { slider } from './view/_slider';
import { contact } from './view/_contact';
// import { news } from './view/_news';

// import { movie } from './view/_movie';
