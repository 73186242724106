const _setHeight = () => {
	const _vh = window.innerHeight * 0.01;
	const _headerHight = document.getElementById('js-header').offsetHeight;
	const _navHight = document.getElementById('js-navigation').offsetHeight;
	document.documentElement.style.setProperty('--vh', `${_vh}px`);
	document.documentElement.style.setProperty('--headerH', `${_headerHight}px`);
	document.documentElement.style.setProperty('--navH', `${_navHight}px`);
}
window.addEventListener('resize', _setHeight);
_setHeight();
