$(window).on('load', function(){
	$('.btnshow .btnmore').on('click', function(){
		$(this).toggleClass('active');
		$('.group_catbox').slideToggle();
	});

	if( $(window).width() < 768 ){
		var minimized_elements = $('p.txtms');
		minimized_elements.each(function(){
			var t = $(this).text();
			if(t.length < 110) return;

			$(this).html(
				t.slice(0,110)+'<span>... </span><span class="spbtn"><a href="#" class="moretext">続きを読む</a></span>'+
				'<span style="display:none;">'+ t.slice(110,t.length)+'</span>'
			);
		});
		$('a.moretext', minimized_elements).click(function(event){
			event.preventDefault();
			$(this).parent().hide();
			$(this).parent().prev().hide();
			$(this).parent().next().show();
		});
	}

});
