if($('.js-evaluation').length){
	let _evaluation = $('.js-evaluation');
	const _maxStarCount = 5;
	_evaluation.each(function(){
		let _e = $(this);
		let _evaluationNum = Number(_e.data('evaluation'));
		let _evaluationStar = _e.find('.js-evaluationColor');
		let _evaluationStarWidth = `${(_evaluationNum / _maxStarCount) * 100}%`;
		_evaluationStar.css('width', _evaluationStarWidth);
	})
}
