const _faqTitle = $('.js-faqTitle');

if(_faqTitle.length){
	_faqTitle.click(function(){
		let _e = $(this);
		_e.find('.js-faqIcon').toggleClass('is-active');
		_e.next().slideToggle();
	})
}
if($('.accVox').length){
	$('.accVox .accTitle').addClass('open');
	$('.accVox .accTitle').on('click', function() {
		if ($('.accContents').hasClass('velocity-animating')) {
			return
		}
		$(this).next('.accContents').slideToggle();
		$(this).toggleClass('open')
	});
}
