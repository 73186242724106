if($('#entrySubmit').length){
	let _btn = $('#entrySubmit');
	let _confirmCheckbox = $('input[name="entryAgree"]');
	let _form = $('form');

	$(_btn).addClass('disabled');

	// confirm checkbox
	_confirmCheckbox.on('click', function() {
		if ( $(this).prop('checked') == false ) {
			$(_btn).addClass('disabled');
		} else {
			$(_btn).removeClass('disabled');
		}
	});

	_btn.on('click', function() {
		_form.submit();
	});

}
