$('a[href^="#"]').click(function(){
	let speed = 500;
	let href= $(this).attr("href");
	let target = $(href == "#" || href == "" ? 'html' : href);
	let distance = Number($('.l-main').css('margin-top').replace('px', '')) + Number('10');;;
	let position = target.offset().top - distance;
	$("html, body").animate({scrollTop:position}, speed, "swing");
	return false;
});

var _timer = '';
var _width = '';
let _ctaArea = $('#js-cta');
let _header = $('#js-header');
$(window).on('load resize', function(){
	_width = window.innerWidth;
	if (_timer) {
		clearTimeout(_timer);
	}
	_timer = setTimeout(function(){
		if(!$('html').hasClass('is-fixed')){
			let _pos = 0;
			function ScrollAnime() {
				var _elemTop = $('.l-aside').offset().top;
				var _scroll = $(window).scrollTop();
				if(_scroll > _elemTop) {
					_ctaArea.addClass('is-hide');
				}else{
					_ctaArea.removeClass('is-hide');
				}
				_pos = _scroll;
			}
			if($('body').hasClass('p-home')){
				let _headerHeight = _header.outerHeight();
				let _kv = $('.p-home__kv');
				let _kvTop = _kv.offset().top;
				let _thought = $('.p-home__thought');
				let _thoughtTop = _thought.offset().top;
				$(window).scroll(function(){
					if($(this).scrollTop() + _headerHeight > _kvTop){
						_header.addClass('is-hide')
						ScrollAnime();
					} else {
						_header.removeClass('is-hide')
						ScrollAnime();
					}
					if($(this).scrollTop() + _headerHeight > _thoughtTop){
						_header.removeClass('is-hide').addClass('is-show');
						ScrollAnime();
					} else {
						_header.removeClass('is-show');
						ScrollAnime();
					}
				})
			} else {
				$(window).scroll(function(){
					ScrollAnime();
				});
			}
		}
	}, 1000);
});

