if($('#js-homeCaseSlider').length){
	let _homeCaseSlider = new Swiper('#js-homeCaseSlider', {
		loop: true,
		loopedSlides: 6,
		centeredSlides: true,
		slidesPerView: 1,
		spaceBetween: 15,
		speed: 2000,
		allowTouchMove: false,
		autoplay: {
			delay: 7000
		},
		breakpoints: {
			1024: {
				slidesPerView: 'auto',
				spaceBetween: 38,
			},
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: 'clickable',
		}
	});
}
