const _header = $('#js-header');
const _menuBtn = $('#js-hamburger');
const _navigation = $('#js-navigation');
const _navItem = _navigation.find('a');
const _navigationBg = $('#js-navigationBg');
const _cta = $('#js-cta');
const _html = $('html');

_menuBtn.click(function() {
	_header.toggleClass('is-open');
	_menuBtn.toggleClass('is-open');
	_navigation.toggleClass('is-open');
	_navigationBg.toggleClass('is-open');
	_cta.toggleClass('is-hidden');
	_html.toggleClass('is-fixed');
});

// animation
// var _timer = '';
// var _width = '';
// $(window).on('load resize', function(){
// 	_width = window.innerWidth;
// 	if (_timer) {
// 		clearTimeout(_timer);
// 	}
// 	_timer = setTimeout(function(){
// 		if(!$('html').hasClass('is-fixed')){
// 			let _pos = 0;
// 			function ScrollAnime() {
// 				var _elemTop = $('#contact').offset().top;
// 				var _scroll = $(window).scrollTop();
// 				if(_scroll > _elemTop) {
// 					_header.addClass('is-hide');
// 				}else{
// 					_header.removeClass('is-hide');
// 				}
// 				_pos = _scroll;
// 			}
// 			$(window).scroll(function(){
// 				ScrollAnime();
// 			})
// 		}
// 	}, 1000);
// });
